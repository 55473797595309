@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  /* background-color: #282c34; */
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(2vmin); */
  /* color: white; */
}

.App-link {
  color: #09d3ac;
}

#alert{
  padding-bottom: 10%;
  padding: 2em;
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}